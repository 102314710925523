<template>
  <div class="qrcode-parser">
    <div class="code-tips">
      审核通过后，可以查看二维码
    </div>
    <div class="time-region">
      <label>开始时间</label>
      <el-date-picker class="time-region_picker"
                      value-format="timestamp"
                      type="datetime"
                      format="yyyy-MM-dd HH:mm"
                      v-model="value.value[0]"></el-date-picker>
    </div>
    <div class="time-region">
      <label>结束时间</label>
      <el-date-picker class="time-region_picker"
                      value-format="timestamp"
                      type="datetime"
                      format="yyyy-MM-dd HH:mm"
                      :picker-options="timeOptions"
                      v-model="value.value[1]"></el-date-picker>
    </div>
  </div>
</template>

<script>
export default {
  name: "QrcodeParser",
  props: {
    value: Object,
  },
  data(){
    return {
      timeOptions:{
        disabledDate:(time)=>{
          if (!this.value.value[0]) { return false }
          return time.getTime() < this.value.value[0]-(24*60*60-1)*1000
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.qrcode-parser{
  .code-tips{
    font-size: 13px;
    color: rgba(25,31,37,0.4);
  }
  .time-region{
    width:100%;
    display:flex;
    margin : 10px;
    flex-direction : row;
    align-items :center;
    label{
      width:85px;
    }
    .time-region_picker{
      flex: 1
    }
  }
}
</style>