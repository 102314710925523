<template>
  <div class="cascader-parser">
    <el-cascader
      v-model="_modelData"
      @change="handleChange"
      v-bind="{
        ...value.props,
        ...privateOptions,
      }"
      :filterable="true"
    ></el-cascader>
  </div>
</template>

<script>
import { Arrays } from "../../utils/Arrays.js";
export default {
  name: "CascaderParser",
  props: {
    value: Object,
    options: Object,
    list: Array,
    parentId: String
  },
  watch: {
    options:{
      deep:true,
      handler(val) {
        const {
          children,
          value: { subType, isMultiple, settings ,id},
        } = this;
        let privateOptions = JSON.parse(JSON.stringify(val[this.type] || {}));
        val[this.type] &&
        val[this.type].callback &&
        val[this.type].callback(privateOptions);
        if (privateOptions && privateOptions.options) {
          privateOptions.props = {
            ...privateOptions.props,
            multiple: isMultiple,
          };
        }
        this.privateOptions = privateOptions;
      }
    },
    'value.value':{
      deep:true,
      immediate: true,
      handler(v){
        if(v){
          this.setActivityOption(v)
        }
      }
    }
  },
  computed: {
    _modelData: {
      get() {
        if (this.value.isMultiple) {
          return (
            this.key &&
            this.value &&
            this.value.value &&
            this.value.value.map((e) => e[this.key])
          );
        } else {
          return (
            this.key &&
            this.value &&
            this.value.value &&
            this.value.value[this.key]
          );
        }
      },
      set(val) {
        const list =
          this.options[this.type] &&
          this.options[this.type].options;
        let res = JSON.parse(JSON.stringify(this.value));
        if (this.value.isMultiple) {
          res.value = val.map((e) => this.getValue(list, e));
        } else {
          res.value = this.getValue(list, val);
        }
        this.$emit("input", res);
      },
    },
    key() {
      return (
        this.options &&
        this.options[this.type] &&
        this.options[this.type].props &&
        this.options[this.type].props.value
      );
    },
    children() {
      return (
        this.options &&
        this.options[this.type] &&
        this.options[this.type].props &&
        this.options[this.type].props.children
      );
    },
    label() {
      return (
        this.options &&
        this.options[this.type] &&
        this.options[this.type].props &&
        this.options[this.type].props.label
      );
    },
  },
  mounted(){
    // 有店铺号 设置默认店铺
    // const userInfo = JSON.parse(localStorage.userInfo || {})
    // if(userInfo.shopCode){
    //       this.list.forEach(item=>{
    //         if(item.myType=='activity'){
    //           item.children.forEach(ite=>{
    //             if(ite.myName=="shop"){
    //               if(!item.value){
    //                 item.value = {
    //                 name: userInfo.shopCode,
    //                 value: userInfo.shopCode,
    //               }
    //               }
    //             }
    //           })
    //         }
    //       })
    //       this.list.forEach(item=>{
    //         if(item.myType=='ecash'){
    //           item.children.forEach(ite=>{
    //             if(ite.myName=="shop"){
    //               if(!item.value){
    //                 item.value = {
    //                 name: userInfo.shopCode,
    //                 value: userInfo.shopCode,
    //               }
    //               }
    //             }
    //           })
    //         }
    //       })
    //   }
  },
  data() {
    return {
      currentShop: '',
      type:this.value.subType+this.value.id.slice(-5),
      privateOptions: {
        // 这里不使用计算属性
        // 原因是计算属性会导致级联选择器不能正确的渲染
        // 疑似是cascader组件内部写法的问题
        // 使用计算属性会使其内部的计算属性multiple与计算属性config的multiple属性不一致
        // 即：
        // computed: {
        //    multiple(){return this.config.multiple}, // multiple == true
        //    config(){...}, // config.multiple == false
        // }
      },
    };
  },
  methods: {
    setActivityOption(v){
      if ((this.value.myChildrenType == "multiMonthChildren" || this.value.myChildrenType == "scheduleChildren" || this.value.myChildrenType == "refundChildren") && this.value.myName == "shop") {
        // 根据店铺查询返款信息
        const userInfo = JSON.parse(localStorage.userInfo || {})
        let currentItemIndex = this.list.findIndex(item => item.id === this.parentId)
        let shop = v.value || null
        if (shop) {
          this.$api.dynamicForm.componentShopAccount({ marketCode: userInfo.marketCode, shopCode: shop }).then(res => {
            if (res.code === 200) {
              if (currentItemIndex || currentItemIndex === 0) {
                let currentInfoIndex = this.list[currentItemIndex].children.findIndex(item => item.myName === 'accountInfo')
                if (currentInfoIndex || currentInfoIndex === 0) {
                  this.list[currentItemIndex].children[currentInfoIndex].value = res.data || {}
                }
              }
            }
          })
        }
      }
    },
    handleChange(value) {
      if (this.value.myChildrenType == "activityChildren" && this.value.myName == "shop") {
        let currentItemIndex = this.list.findIndex(item => item.id === this.parentId)
        if (currentItemIndex || currentItemIndex === 0) {
          this.list[currentItemIndex].children.forEach(item => {
            if (item.myName == "commission") {
              item.value = ''
            }
            if (item.myName == "amount") {
              item.value = ''
            }
            if (item.myName == "thisAmount") {
              item.value = ''
            }
            if (item.myName == "activityName") {
              item.options = []
              item.value=''
            }
          })
        }
      }
      // 选店铺后重置
      if (this.value.myChildrenType == "ecashChildren" && this.value.myName == "shop") {
        let currentItemIndex = this.list.findIndex(item => item.id === this.parentId)
        if (currentItemIndex || currentItemIndex === 0) {
          this.list[currentItemIndex].children.forEach(item => {
            if (item.myName == "commission") {
              item.value = ''
            }
            if (item.myName == "amount") {
              item.value = ''
            }
            if (item.myName == "thisAmount") {
              item.value = ''
            }
            if (item.myName == "cardType") {
              item.value = ''
            }
            if (item.myName == "date") {
              item.value = ''
            }
          })
        }
      }
      if (this.value.myChildrenType == "multiMonthChildren" && this.value.myName == "shop") {
        let currentItemIndex = this.list.findIndex(item => item.id === this.parentId)
        if (currentItemIndex || currentItemIndex === 0) {
          this.list[currentItemIndex].children.forEach(item => {
            if (item.myName === 'cardType') {
              item.value = {}
            }
            if (item.myName === 'months') {
              item.value = []
            }
            if (item.myName === 'refundAccountInfo') {
              item.value = []
            }
          })
        }
      }
      if (this.value.myChildrenType == "scheduleChildren" && this.value.myName == "shop") {
        let currentItemIndex = this.list.findIndex(item => item.id === this.parentId)
        if (currentItemIndex || currentItemIndex === 0) {
          this.list[currentItemIndex].children.forEach(item => {
            if (item.myName === 'activityType') {
              item.value = {}
            }
            if (item.myName === 'activitySchedule') {
              item.value = {}
            }
            if (item.myName === 'activityNames') {
              item.value = []
            }
            if (item.myName === 'activityRefundInfo') {
              item.value = []
            }
          })
        }
      }
      if (this.value.myChildrenType == "refundChildren" && this.value.myName == "shop") {
        if (this.currentShop !== value) {
          this.changeRefund(value)
          this.$bus.$emit('changeShop', value)
        }
        this.currentShop = value
      }
    },
    changeRefund () {
      this.list.forEach(item => {
        if (item.type === 'approve') {
          item.value = {
            activitySchedules: [],
            activityNames: [],
            coupons: [],
            refunds: []
          }
        }
      })
    },
    getValue(list, val) {
      let res;
      res = new Arrays({ children: this.children }).find(list, (e) => {
        return e[this.key] == val;
      });
      const {valueFormatter} = this.options[this.type]
      res = valueFormatter ? valueFormatter(res) : (
          res && {
            [this.key]: res[this.key],
            [this.label]: res[this.label],
          }
      )
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
.cascader-parser {
}
</style>