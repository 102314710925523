<template>
  <div class="uploader-parser">
    <template v-if="value.subType == 'example'">
      <div class="example-img">
        <el-image style="width: 100px; height: 100px" v-for="img in srcList" :key="img" :src="img" :preview-src-list="[img]"></el-image>
      </div>
    </template>
    <template v-if="value.subType == 'image'">
      <el-upload
        action="#"
        list-type="picture-card"
        accept=".jpg,.jpeg,.png"
        multiple
        :auto-upload="false"
        :file-list="fileList"
        :on-change="handleChange"
        :on-remove="handleRemove"
        :on-preview="handlePictureCardPreview"
        :on-exceed="overFile"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </template>
    <template v-if="value.subType == 'attachment'">
      <el-upload
        action="#"
        accept=".jpg,.jpeg,.png,.doc,.docx,.pdf,.zip,.rar"
        multiple
        :show-file-list="false"
        :auto-upload="false"
        :on-change="handleChange"
        :on-remove="handleRemove"
        :on-preview="handlePreview"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">
          <span class="tip">只能上传jpg/jpeg/png/doc/docx/pdf/zip/rar文件</span>
        </div>
      </el-upload>
      <attachments class="enclosure" :fileList.sync="value.value" :canDelete="true"></attachments>
    </template>
  </div>
</template>

<script>
import uploadCommon from "@/utils/upload";
import { isIE } from "@/utils/utils";
import { Loading } from "element-ui";
import { processUpload } from "@/services/check";
import Attachments from "@/pages/businessModule/examine/formDetail/attachments";
export default {
  name: "UploaderParser",
  components: {Attachments},
  props: {
    value: Object,
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  computed: {
    fileList() {
      return this.value.value.map(({ name, url }) => ({
        name: name,
        url: url,
      }));
    },
    srcList() {
      return this.value.value.map(item => {
        return item.url
      })
    }
  },
  methods: {
    overFile(file,filelist){
      // this.$message.error("最多上传5个文件");
    },
    handleChange(file) {
      // add
      if (!this.beforeUpload(file)) return;
      if (this.value.subType == "image") {
        this.uploadAttachment(file.raw);
      } else if (this.value.subType == "attachment") {
        this.uploadAttachment(file.raw);
      }
    },
    handleRemove(file) {
      // remove
      const index = this.value.value.findIndex((e) => e.url == file.url);
      this.value.value = [
        ...this.value.value.slice(0, index),
        ...this.value.value.slice(index + 1),
      ];
    },
    handlePreview(file) {
      // 附件的预览？
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeUpload(file, data) {
      //验证文件格式和大小最大100M
      return uploadCommon.checkFileAndSize(file, 100,{'attachment':['jpg','jpeg','png','doc','docx','pdf','zip','rar'],'image':['jpg','jpeg','png']}[this.value.subType]);
      // if(this.value.value.length<5){
      //   //验证文件格式和大小最大100M
      //   return uploadCommon.checkFileAndSize(file, 100,{'attachment':['jpg','jpeg','png','doc','docx','pdf','zip','rar'],'image':['jpg','jpeg','png']}[this.value.subType]);
      // }else{
      //   this.$message.error("最多上传5个文件");
      //   return false;
      // }
    },
    // uploadImage(file) {
    //   //上传给后端，拿到url
    //   const form = new FormData();
    //   form.append("file", file);
    //   this.$api.sysapi
    //     .uploadFile(form, "dynamic-form")
    //     .then(({ code, data, msg }) => {
    //       if (code === 200) {
    //         this.value.value.push(data);
    //       }
    //     });
    // },
    async uploadAttachment(file) {
      // 如果是cad和pdf文件不需要压缩，直接上传
      //上传给后端，拿到url
      // const file = file.file;
      let form = new FormData();
      const type = file.type.split("/")[1];
      let ie = isIE();
      if (["png", "jpg", "jpeg"].includes(type) && !ie) {
        const img = await uploadCommon.readImg(file);
        const blob = await uploadCommon.compressImg(img, 500, 500, file.type);
        form.append(
          "file",
          new window.File([blob], file.name, { type: file.type })
        );
      } else {
        form.append("file", file);
      }
      form.append("biz", 9);
      const loading = Loading.service({
        lock: true,
        text: "文件上传中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      processUpload(form)
        .then(({ code, data }) => {
          if (code === 200) {
            this.value.value.push({
              name: data.fileName,
              url: data.fileUrl,
              size: data.fileSize,
              id: data.id||'',
              isPicturePreview:data.isPicturePreview
            });
          }
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.uploader-parser {
  .tip {
    word-break: break-all;
  }
}
.example-img {
  display: flex;
  flex-wrap: wrap;
}
</style>
<style lang="scss">
.uploader-parser {
  .el-upload--picture-card {
    width: 124px;
    line-height: 122px;
    height: 124px;
  }

  .el-upload-list--picture-card {
    .el-upload-list__item {
      width: 124px;
      height: 124px;
    }
  }
}
</style>