<template>
  <div class="area-parser">
    <el-cascader
      v-model="_modelData"
      v-bind="{
        ...value.props,
        ...privateOptions,
      }"
      ref="areaCascader"
      @change="handleChange"
    ></el-cascader>
  </div>
</template>

<script>
export default {
  name: "AreaParser",
  props: {
    value: Object,
    options: Function,
  },
  data() {
    return {
      privateOptions: {}
    };
  },
  created() {
    this.privateOptions = this.options.call(this, this.value);
  },
  computed:{
    _modelData:{
      get(){
        return (
            this.key &&
            this.value &&
            this.value.value &&
            this.value.value[this.key]
        )
      },
      set(val){
        let res = JSON.parse(JSON.stringify(this.value));
        res.value = this.getValue()
        this.$emit("input",res)
      }
    },
    key(){
      return  (
          this.privateOptions &&
          this.privateOptions.props &&
          this.privateOptions.props.value
      )
    },
    label(){
      return (
          this.privateOptions &&
          this.privateOptions.props &&
          this.privateOptions.props.label
      )
    }
  },
  methods: {
    handleChange(value) {
      // ...
    },
    getValue(){
      const _select = this.$refs.areaCascader.getCheckedNodes()[0];
      return {
        [this.key]:_select.path,
        [this.label]:_select.pathLabels.join(',')
      }
    }
  },



};
</script>

<style lang="scss" scoped>
.area-parser {
}
</style>