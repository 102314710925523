<template>
  <div class="number-input">
    <el-input v-model="_value" v-bind="$attrs" @input="onInput"></el-input>
  </div>
</template>

<script>
export default {
  name: "NumberInput",
  props: {
    type: {
      type: String,
      default: "",
    },
    value: String,
  },
  computed: {
    _value: {
      get(){
        return this.value;
      },
      set(val){
        this.$emit('input', val);
      }
    }
  },
  methods: {
    onInput(val) {
      if (!this.type) {
        let res = val.match(/[0-9\.]/g);
        if (res) {
          let dot = res.findIndex((e) => e == ".");
          if (dot != -1) {
            res = res.filter((e) => e != ".");
            res = [...res.slice(0, dot), ".", ...res.slice(dot, dot + 10)];
          }
        }
        this._value = (res || []).join("");
      } else if (this.type == "IDCard") {
        let res = val.match(/[0-9xX]/g);
        this._value = (res || []).join("");
      } else if (this.type == "money") {
        let res = val.match(/[0-9\.]/g);
        if (res) {
          let dot = res.findIndex((e) => e == ".");
          if (dot != -1) {
            res = res.filter((e) => e != ".");
            res = [...res.slice(0, dot), ".", ...res.slice(dot, dot + 2)];
          }
        }
        this._value = (res || []).join("");
      } else if (this.type == "phone") {
        let res = val.match(/[0-9]/g);
        this._value = (res || []).join("");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.number-input {
}
</style>