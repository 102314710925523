<template>
  <div class="textarea-parser">
    <el-input
      type="textarea"
      :autosize="{ minRows: 2, maxRows: 3 }"
      v-model="value.value"
      v-bind="value.props"
      :maxlength="500"
      show-word-limit
      resize="none"
    >
    </el-input>
  </div>
</template>

<script>
export default {
  name: "TextareaParser",
  props: {
    value: Object,
  },
};
</script>

<style lang="scss" scoped>
.textarea-parser {
}
</style>