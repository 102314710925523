<template>
  <div class="json-parser" v-if="isShow">
    <div class="content">
      <template v-if="_value.type == 'subfield'">
        <component-wrapper
          :title="_value.label"
          :isShowTitle="!['caption', 'subfield'].includes(_value.type)"
        >
          <el-col
            :span="getSpan(ele)"
            v-for="(ele, index) in _value.children"
            :key="ele.id"
          >
            <JSON-parser
              v-model="_value.children[index]"
              :list="list"
              :options="options"
              :ref="ele.id"
              :parentId="_value.id"
              :approveDataId="approveDataId"
              :excludeApprovalId="excludeApprovalId"
              :approveCode="approveCode"
            ></JSON-parser>
          </el-col>
        </component-wrapper>
      </template>
      <template v-else>
        <component-wrapper
          :title="_value.label"
          :required="_value.props && _value.props.required"
          :isShowTitle="!['caption', 'subfield'].includes(_value.type)"
        >
          <el-form :model="_value" ref="form" :rules="rules"  @submit.native.prevent="() => false">
            <el-form-item prop="value">
              <component
                :is="`${_value.type}-parser`"
                v-model="_value"
                v-bind="_value.props"
                :list="list"
                :parentId="parentId"
                :approveDataId="approveDataId"
                :excludeApprovalId="excludeApprovalId"
                :approveCode="approveCode"
                :options="options[_value.type]"
              ></component>
            </el-form-item>
          </el-form>
        </component-wrapper>
      </template>
    </div>
  </div>
</template>

<script>
import ComponentWrapper from "./ComponentWrapper.vue";
import * as Parser from "./JSONParser/index.js";
import { flatten, _validateIdCard } from "../utils/utils.js";

export default {
  name: "JSONParser",
  components: {
    ComponentWrapper,
    ...Parser,
  },
  props: {
    list: Array,
    value: Object,
    options: Object,
    parentId: String,
    approveDataId: [Number, String],
    excludeApprovalId: [Number, String],
    approveCode: [Number, String],
  },
  computed: {
    rules() {
      let validator = (r, v, c) => c();
      if (
        this._value.type == "numberInput" &&
        this._value.subType == "phone" &&
        this._value.phoneType == "phone"
      ) {
        validator = (rule, value, cb) => {
          if (/^1[0-9]{10}$/.test(value) || !value) {
            cb();
          } else {
            cb(new Error("请输入正确的手机号"));
          }
        };
      }
      if (
        this._value.type == "numberInput" &&
        this._value.subType == "IDCard"
      ) {
        validator = (rule, value, cb) => {
          if (_validateIdCard(value) || !value) {
            cb();
          } else {
            cb(new Error("请输入正确的身份证号"));
          }
        };
      }
      if(this._value.type == "qrcode"){
        validator  = (rule,value,cb) => {
          if(!value || value.length <2 || !(value[0]&&value[1])){
            cb(new Error('请填入开始时间和结束时间'))
          }else if(value[0]>value[1]){
            cb(new Error('开始时间不能大于结束时间'))
          }
          cb()
        }
      }
      if (
        this._value.type == "singleSelector" &&
        this._value.subType == "activityType"
      ) {
        validator = (rule, value, cb) => {
          if (value && Object.keys(value).length) {
            cb();
          } else {
            cb(new Error("请选择活动分类"));
          }
        };
      }
      if (
        this._value.type == "singleSelector" &&
        this._value.subType == "activitySchedule"
      ) {
        validator = (rule, value, cb) => {
          if (value && Object.keys(value).length) {
            cb();
          } else {
            cb(new Error("请选择活动档期"));
          }
        };
      }
      if (
        this._value.type == "singleSelector" &&
        this._value.subType == "cardType"
      ) {
        validator = (rule, value, cb) => {
          if (value && Object.keys(value).length) {
            cb();
          } else {
            cb(new Error("请选择卡类型"));
          }
        };
      }
      if (
        this._value.type == "cascader" &&
        this._value.subType == "shop" && this._value.myName == "shop"
      ) {
        validator = (rule, value, cb) => {
          if (value && Object.keys(value).length) {
            cb();
          } else {
            cb(new Error("请选择店铺"));
          }
        };
      }
      const rules = {
        value: [
          {
            validator,
            trigger: ["blur"],
          },
        ],
      };
      if (this._value.props && this._value.props.required) {
        rules.value.push({
          required: true,
          message: `请输入${this._value.label}`,
          trigger: "blur",
        });
      }
      return rules;
    },
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isShow() {
      // 单选和多选的关联
      const getShow = (id) => {
        let dependSelectorsMap = this.__formRelationships[id];
        const flatten1 = (list) => {
        return list
          .reduce((res, ele) => {
            if (ele.type == "subfield" && !ele.myType) {
              res.push(...ele.children);
            } else {
              res.push(ele);
            }
            return res;
          }, [])
      }
        if (dependSelectorsMap) {
          return flatten1(this.list)
            .filter((e) => Object.keys(dependSelectorsMap).includes(e.id)) // 找出所有跟自己关联的选择器对象
            .some((ele) => {
              if (ele.type == "multipleSelector") {
                return (
                  dependSelectorsMap[ele.id].some((e) =>
                    ele.value.includes(e)
                  ) && getShow(ele.id)
                );
              } else if (ele.type == "singleSelector") {
                return (
                  dependSelectorsMap[ele.id].includes(ele.value) &&
                  getShow(ele.id)
                );
              }
            });
        }
        return true;
      };
      const result = getShow(this._value.id);
      this._value.wx_show = result;
      if(this._value.myType){
        this.$emit('getData')
      }
      return result;
    },
    __formRelationships() {
      // 用于生成组件间关联关系的表
      // 生成一份映射关系，可以知道每一个表单关联哪一个表单的哪一个选项
      const flatten1 = (list) => {
        return list
          .reduce((res, ele) => {
            if (ele.type == "subfield" && !ele.myType) {
              res.push(...ele.children);
            } else {
              res.push(ele);
            }
            return res;
          }, [])
      }
      const flatList = flatten1(this.list);
      const res = flatList
        .filter((e) => ["singleSelector", "multipleSelector"].includes(e.type))
        .map((selector) => {
          // 处理选项关联了其他表单之后，表单又被删除的情况
          selector.options.forEach((o) => {
            let res = o.relations.filter((r) =>
              flatList.map((e) => e.id).includes(r)
            );
            if (res.length != o.relations.length) {
              // 避免栈溢出
              o.relations = res;
            }
          });
          return selector;
        })
        .reduce((res, selector) => {
          selector.options.forEach((o) => {
            o.relations.forEach((r) => {
              if (res[r]) {
                if (res[r][selector.id]) {
                  res[r] = {
                    ...res[r],
                    [selector.id]: [...res[r][selector.id], o.id],
                  };
                } else {
                  res[r] = { ...res[r], [selector.id]: [o.id] };
                }
              } else {
                res[r] = { [selector.id]: [o.id] };
              }
            });
          });
          return res;
        }, {});
      // console.log(res);
      return res;
    },
  },
  methods: {
    getSpan(value) {
      if (value.style && value.style.width && value.style.width == "50%") {
        return 12;
      } else {
        return 24;
      }
    },
    validate() {
      const p1 = new Promise((res, rej) => {
        if (this.$refs.form) {
          this.$refs.form.validate((valid) => {
            valid ? res() : rej(this._value.label);
          });
        } else {
          res();
        }
      });
      let p2 = [];
      if (
        this._value.type == "subfield" &&
        this._value.children &&
        this._value.children.length
      ) {
        p2 = this._value.children.map((item) => {
          return (this.$refs[item.id] || [])[0] ? this.$refs[item.id][0].validate() : 1  //当组件被隐藏时 不需要检查表单必填 需要返回一个成功的promis
        });
      }
      return Promise.all([p1, ...p2]);
    },
  },
};
</script>

<style lang="scss" scoped>
$blue: rgb(24, 144, 255);

.json-parser {
  position: relative;
  width: 100%;
  margin-bottom: 8px;

  .content {
    .title {
    }
  }

  .subfield {
    // 容器样式
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
  }

  .operations {
    position: absolute;
    right: 0;
    top: 0;

    .operation {
      cursor: pointer;
      margin-right: 4px;
      color: $blue;
      font-size: 12px;
    }
  }
}
</style>