<template>
  <div class="single-selector-parser">
    <el-select
      v-model="_modelData"
      :placeholder="value.props.placeholder"
      @change="handleChange"
      v-if="['multiMonthChildren', 'scheduleChildren'].includes(value.myChildrenType)"
    >
      <el-option
        v-for="(item, index) in value.options"
        :key="index"
        :label="item.value"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <template v-else>
      <el-select
      v-model="value.value"
      :placeholder="value.props.placeholder"
      @change="handleChange"
      v-if="!value.isSpread"
    >
      <el-option
        v-for="(item, index) in value.options"
        :key="index"
        :label="item.value"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <div v-if="value.isSpread" class="radio-group">
      <el-radio
        v-model="value.value"
        :label="item.id"
        v-for="(item, index) in value.options"
        :key="index"
        class="radio"
      >
        <span>{{ item.value }}</span>
      </el-radio>
    </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "SingleSelectorParser",
  props: {
    value: Object,
    list: Array,
    parentId: String,
    approveDataId: [Number, String]
  },
  watch:{
    'value.value':{
      immediate:true,
      deep:true,
      handler(v){
        if(v){
          this.getOtherValue(v)
        }
      }
    }
  },
  computed: {
    _modelData: {
      get() {
        return (
            this.value &&
            this.value.value &&
            this.value.value &&
            this.value.value.value
          );
      },
      set(val) {
        let res = JSON.parse(JSON.stringify(this.value));
        let currentItem = this.value.options.find(item => item.id === val)
        res.value = {
          value: val,
          name: currentItem ? currentItem.value : ''
        };
        this.$emit("input", res);
      },
    },
  },
  methods:{
    getOtherValue(v){
      const userInfo = JSON.parse(localStorage.userInfo)
      let shop = null
      this.list.forEach(item=>{
        if(item.myType=='activity'){
          item.children.forEach(ite=>{
            if(ite.myName=="shop" && ite.myChildrenType){
          shop = ite.value.value || null 
        }
          })
        }
      })
      this.list.forEach((item,index)=>{
        if(item.myType=='activity' && this.value.myName == 'activityName'){
            this.$api.dynamicForm.componentActivityRefund({marketCode: userInfo.marketCode, shopCode:  shop, activities: v}).then(res=>{
              if(res.code==200 && res.data){
                item.children.forEach(item=>{
              if(item.myName=='commission'){
                // 手续费
                item.value = res.data.feeAmount
              }
              if(item.myName=='amount'){
                // 核销金额
                item.value = res.data.consumeAmount
              }
              if(item.myName=='thisAmount'){
                // 本次请款金额
                item.value = res.data.refundAmount
              }
            })
              }
            })
        }
        let shop2 = null
      this.list.forEach(item=>{
        if(item.myType=='ecash'){
          item.children.forEach(ite=>{
            if(ite.myName=="shop" && ite.myChildrenType=='ecashChildren'){
          shop2 = ite.value.value || null 
        }
          })
        }
      })
        if(item.myType=='ecash' && this.value.myName == 'cardType'){
          let dateTime = (item.children.filter(i=>{return i.myName=='date'}))[0].value
          let month =dateTime ? new Date(dateTime).getFullYear() + '-' + (new Date(dateTime).getMonth()+1).toString().padStart(2,'0') : ''
          if(month&&shop2){
            this.$api.dynamicForm.componentCardTypeRefund({marketCode: userInfo.marketCode, shopCode:  shop2 ,cardType: v, month: month}).then(res=>{
            if(res.code==200 && res.data){
              item.children.forEach(ite=>{
              if(ite.myName=='commission'){
                // 手续费
                ite.value = res.data.feeAmount
              }
              if(ite.myName=='amount'){
                // 核销金额
                ite.value = + res.data.consumeAmount
              }
              if(ite.myName=='thisAmount'){
                // 本次请款金额
                ite.value = + res.data.refundAmount
              }
            })
            }
          })
          }
        }
      })

      if (this.value.myChildrenType == "scheduleChildren" ) {
        let currentItemIndex = this.list.findIndex(item => item.id === this.parentId)
        if (currentItemIndex || currentItemIndex === 0) {
          let shopCode = this.list[currentItemIndex].children.find(item => item.myName === 'shop').value.value
          let classifyId = this.list[currentItemIndex].children.find(item => item.myName === 'activityType').value.value
          let schedule = this.list[currentItemIndex].children.find(item => item.myName === 'activitySchedule').value.value
          if (this.value.myName == "activityType" && userInfo.marketCode && shopCode && classifyId) {
            let params = {
              marketCode: userInfo.marketCode,
              shopCode,
              classifyId
            }
            this.$api.dynamicForm.componentEmaSchedule(params).then(res => {
              if (res.code === 200) {
                this.list[currentItemIndex].children.forEach(item => {
                  if (item.myName === 'activitySchedule') {
                    item.options = res.data ? res.data.map(item => {
                      return {
                        value: item.text,
                        id: item.value,
                        relations: [] // 关联关系
                      }
                    }) : []
                  }
                })
              }
            })
          }
          if (this.value.myName == "activitySchedule" && userInfo.marketCode && shopCode && classifyId && schedule) {
            let params = {
              marketCode: userInfo.marketCode,
              shopCode,
              classifyId,
              schedule,
              approveDataId: this.approveDataId
            }
            this.$api.dynamicForm.componentEmaSelect(params).then(res => {
              if (res.code === 200) {
                this.list[currentItemIndex].children.forEach(item => {
                  if (item.myName === 'activityNames') {
                    item.options = res.data ? res.data.map(item => {
                      return {
                        value: item.text,
                        id: item.value,
                        relations: [] // 关联关系
                      }
                    }) : []
                  }
                })
              }
            })
          }
        }
      }

      if (this.value.myChildrenType == "multiMonthChildren") {
        let currentItemIndex = this.list.findIndex(item => item.id === this.parentId)
        if (currentItemIndex || currentItemIndex === 0) {
          let shopCode = this.list[currentItemIndex].children.find(item => item.myName === 'shop').value.value
          let cardType = v.value
          if (this.value.myName == "cardType" && userInfo.marketCode && shopCode && cardType) {
            let params = {
              marketCode: userInfo.marketCode,
              shopCode,
              cardType,
              approveDataId: this.approveDataId
            }
            this.$api.dynamicForm.shopCarddisable(params).then((res) => {
              if (res.code === 200) {
                let currentInfoIndex = this.list[currentItemIndex].children.findIndex(item => item.myName === 'months')
                if (currentInfoIndex || currentInfoIndex === 0) {
                  this.list[currentItemIndex].children[currentInfoIndex].disabledMonths = res.data || []
                }
              }
            })
          }
        }
      }
    },
    handleChange(value) {
      if (this.value.myChildrenType == "multiMonthChildren" && this.value.myName == "cardType") {
        let currentItemIndex = this.list.findIndex(item => item.id === this.parentId)
        if (currentItemIndex || currentItemIndex === 0) {
          this.list[currentItemIndex].children.forEach(item => {
            if (item.myName === 'months') {
              item.value = []
            }
            if (item.myName === 'refundAccountInfo') {
              item.value = []
            }
          })
        }
      }
      if (this.value.myChildrenType == "scheduleChildren" ) {
        let currentItemIndex = this.list.findIndex(item => item.id === this.parentId)
        if (currentItemIndex || currentItemIndex === 0) {
          if (this.value.myName == "activityType") {
            this.list[currentItemIndex].children.forEach(item => {
            if (item.myName === 'activitySchedule') {
              item.value = {}
            }
            if (item.myName === 'activityNames') {
              item.value = []
            }
            if (item.myName === 'activityRefundInfo') {
              item.value = []
            }
          })
          }
          if (this.value.myName == "activitySchedule") {
            this.list[currentItemIndex].children.forEach(item => {
            if (item.myName === 'activityNames') {
              item.value = []
            }
            if (item.myName === 'activityRefundInfo') {
              item.value = []
            }
          })
          }

        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.single-selector-parser {
  .radio-group {
    display: flex;
    flex-direction: column;

    .radio {
      margin: 8px 0;
    }
  }
}
</style>