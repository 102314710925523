<template>
  <div class="dynamic-form">
    <!-- <el-button @click="validate">校验表单</el-button> -->
    <!-- <el-button @click="reset">重置</el-button> -->
    <div
      class="item"
      v-for="(item, index) in previewingComponentList"
      :key="item.id"
    >
      <JSON-parser
        v-model="previewingComponentList[index]"
        :list="previewingComponentList"
        :options="JSONParserOptions"
        :approveDataId="approveDataId"
        :excludeApprovalId="excludeApprovalId"
        :approveCode="approveCode"
        :ref="item.id"
        @getData='initData'
      ></JSON-parser>
    </div>
  </div>
</template>

<script>
import JSONParser from "./components/JSONParser.vue";
import { Arrays } from "./utils/Arrays.js";
import { flatten } from "./utils/utils.js";
// TODO 上传组件接口接入，无需支持视频
// TODO 上传附件除了图片的预览，还有其他文件的预览，目前已有的有pdf的预览
// TODO cascader组件要支持单选和多选可配置

export default {
  name: "DynamicForm",
  components: {
    JSONParser,
  },
  props: {
    debounce: {
      // 防抖时间设置
      type: [String, Number],
      default: 1000,
    },
    value: [Object, Array],
    approveDataId: [Number, String],
    excludeApprovalId: [Number, String],
    approveCode: [Number, String],
  },
  computed: {
    previewingComponentList: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      _hasUpdated: false,
      JSONParserOptions: {
        // 这里是配置项，用于把动态的数据传入到组件中
        // 考虑可以把上传组件的接口的配置也写在这里面
        // 这里的设计考虑的是，一个大对象，里面先是type值，然后又套一个对象
        // 对象里是subType值，里面就是组件所需要的数据
        // subType的获取，是放在组件里面获取的，也就是要不要用subType，由组件自己控制
        cascader: {},
        upload: {
          // TODO 上传组件的配置项逻辑还未抽取出来
          image: {},
          attachment: {},
        },
        area: {},
      },
      labelKey: {
        contacts: "name",
        job: "postName",
        department: "deptName",
        brand: "text",
        shop: "text",
        industry: "text",
      },
      idKey: {
        contacts: "userId",
        job: "id",
        department: "id",
        brand: "value",
        shop: "value",
        industry: "value",
      },
      _timer: null,
    };
  },
  created() {
    this.initData();
    // console.log(this.previewingComponentList);
  },
  methods: {
    setActivityOption(item){
      // 活动名称设置选项
      const userInfo = JSON.parse(localStorage.userInfo || {})
      let shop = null
      flatten(this.previewingComponentList).forEach(item=>{
        if(item.myName=="shop" && item.myChildrenType=='activityChildren'){
          shop = item.value.value || null 
        }
      })
      if(shop){
        this.$api.dynamicForm.componentActivity({marketCode: userInfo.marketCode, shopCode:  shop}).then(res=>{
        if(res.code==200){
          item.options=[]
          // item.value=''
          res.data.forEach(t=>{
            item.options.push({
              value: t.text,
              id: t.value,
              relations: [] // 关联关系
            })
          })
        }else{
          item.options=[]
        }
      }).catch(e=>{item.options=[]})
      }else{
        item.options=[]
      }
      // item.options = [{ 
      //       value: "哈哈哈",
      //       id:'12776',
      //       relations: [] // 关联关系
      //     }, {
      //       value: "什么东西",
      //       id:'12786',
      //       relations: [] 
      //     }, {
      //       value: "你要干什么",
      //       id:'12196',
      //       relations: [] 
      //     }]
    },
    setActivityTypeOption(item) {
      const userInfo = JSON.parse(localStorage.userInfo)
      this.$api.dynamicForm.componentEmaClassify({marketCode: userInfo.marketCode}).then(res=>{
        if(res.code==200){
          item.options=[]
          res.data.forEach(t=>{
            item.options.push({
              value: t.text,
              id: t.value,
              relations: [] // 关联关系
            })
          })
        }else{
          item.options=[]
        }
      }).catch(e=>{item.options=[]})
    },
    setCardOption(item){
      // 卡类型设置选项
      const userInfo = JSON.parse(localStorage.userInfo)
      this.$api.dynamicForm.componentCardType({marketCode:userInfo.marketCode}).then(res=>{
        if(res.code==200){
          item.options=[]
          res.data.forEach(t=>{
            item.options.push({
              value: t.text,
              id: t.value,
              relations: [] // 关联关系
            })
          })
        }else{
          item.options=[]
        }
      }).catch(e=>{item.options=[]})
      // item.options = [{ 
      //       value: "哈哈哈1",
      //       id:'12776',
      //       relations: [] // 关联关系
      //     }, {
      //       value: "什么东西1",
      //       id:'12786',
      //       relations: [] 
      //     }, {
      //       value: "你要干什么1",
      //       id:'12196',
      //       relations: [] 
      //     }]
    },    
    initData() {
      console.log('flatten(this.previewingComponentList):',flatten(this.previewingComponentList))
      flatten(this.previewingComponentList).forEach((item) => {
        if(item.myName == "activityName"){
          // 活动名称设置选项
          this.setActivityOption(item)
        }
        if(item.myName == "cardType"){
          // 卡类型设置选项
          this.setCardOption(item)
        }

        if(item.myName == "activityType"){
          // 活动分类设置选项
          this.setActivityTypeOption(item)
        }
        if (item.type == "cascader") {
          this.getCascaderData(item);
        }
        if (item.type == "area") {
          this.setAreaConfig(item);
        }
      });
    },
    setAreaConfig(item) {
      this.$set(this.JSONParserOptions, "area", (item) => {
        return {
          showAllLevels: true,
          props: {
            emitPath: false,
            label: "name",
            value: "value",
            lazy: true,
            lazyLoad: (node, resolve) => {
              const level = {
                city: 1,
                area: 2,
                town: 3,
              }[item.areaUnit];
              this.$api.dynamicForm
                  .componentRegion(node.root ? "" : node.data.code)
                  .then(({ data=[] }) => {
                    resolve(
                        data.map((e) => {
                          e.value = e.code;
                          e.leaf = node.level >= level;
                          delete e.children;
                          return e;
                        })
                    );
                  });
            },
          },
        }
      });
    },
    getCascaderData(item) {
      const func = {
        brand: this.$api.dynamicForm.componentBrand,
        industry: this.$api.dynamicForm.componentBusiness,
        shop: this.$api.dynamicForm.componentShop,
        department: this.$api.dynamicForm.componentDept,
        job: this.$api.dynamicForm.componentPost,
        contacts: this.$api.dynamicForm.componentUser,
      }[item.subType];
      func().then(({ code, data }) => {
        this.$set(
            this.JSONParserOptions.cascader,
            item.subType+item.id.slice(-5),
            this.getCascaderOptions(item, data)
        );
      });
    },

    getCascaderOptions({ subType: type, settings,isMultiple }, data) {
      Array.isArray(data) || (data = [data]);
      const children = ["brand", "shop", "industry"].includes(type)
        ? "child"
        : "children";
      const iterator = new Arrays({ children });
      data = iterator.map(data, (item) => {
        if (item[children] && !item[children].length) {
          delete item[children];
        }
        // 此处为兼容小程序的写法，统一把数据对象处理{name, value}的形式
        // 实际上组件可在配置项设置lable和value
        // 处理name
        const label = item[this.labelKey[type]];
        delete item[this.labelKey[type]];
        item.name = label;
        // 处理id  联系人控件判断是否有userId，没有userId则取deptId
        const value = item[this.idKey[type]];
        delete item[this.idKey[type]];
        item.value = value || 'd-'+item.deptId ;
        return item;
      });
      const res = {
        options: data,
        showAllLevels: false,
        props: {
          expandTrigger: "click",
          checkStrictly: ["job", "department"].includes(type)||(type=="contacts"&&isMultiple),
          emitPath: false,
          children,
          // 此处统一设置为name和id
          label: "name",
          value: "value",
        },
      };
      if (type == "industry") {
        res.callback = (options) => {
          iterator.forEach(options.options, (item, index, arr, { level }) => {
            if (level == settings.industryLevel) {
              delete item[children];
            }
          });
        };
      }
      if (type == "contacts") {
        res.valueFormatter = value => value;
      }
      return res;
    },
    validate() {
      return new Promise((res, rej) => {
        Promise.all(
          this.previewingComponentList.map((item) =>
            this.$refs[item.id][0].validate()
          )
        )
          .then(res)
          .catch((err) => {
            this.$message.error(`请填写${err}`);
            rej(err);
          });
      });
    },
    reset() {
      flatten(this.previewingComponentList).forEach((e) => {
        !["example"].includes(e.subType) && !["caption", "computed"].includes(e.type) &&
          e.value &&
          (e.value = new e.value.constructor().valueOf());
          if(e.myName || e.myName){
            e.value = ""
          }
      });
    },
  },
  watch: {
    previewingComponentList: {
      immediate: true,
      deep: true,
      handler() {
        if (!this._hasUpdated) {
          // 简陋的立即执行
          this.$emit("update");
          return (this._hasUpdated = true);
        }
        // 把防抖做在组件里面而不是外面
        if (this._timer) {
          clearTimeout(this._timer);
        }
        this._timer = setTimeout(() => {
          this.$emit("update");
          this._timer = null;
        }, this.debounce);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dynamic-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .library {
    min-width: 280px;
    max-width: 280px;
    background-color: rgba(17, 31, 44, 0.2);
    height: 100%;
    padding: 8px;
    box-sizing: border-box;
    overflow: auto;

    .item {
      cursor: grab;
    }
  }

  .preview {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    .preview-tab {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 8px;
      box-sizing: border-box;

      span {
        margin-right: 8px;
      }
    }

    .component-wrapper {
      flex-grow: 1;
      height: 500px;
      overflow: hidden;

      .component {
        height: 100%;
        max-height: 100%;
        min-height: 100%;
        overflow: auto;
        width: 100%;
        box-sizing: border-box;
        background-color: rgba(200, 200, 200, 0.1);
      }
    }

    .web {
      // web端的样式
      padding: 0 8px;
    }

    .mobile {
      // 移动端的样式
      display: flex;
      justify-content: center;
      align-items: center;

      .component {
        border-radius: 8px;
        padding: 8px;
        width: 360px;
        border: 1px solid rgba(17, 31, 44, 0.2);
        position: relative;
      }
    }
  }

  .properties {
    background-color: rgba(17, 31, 44, 0.2);
    min-width: 300px;
    max-width: 300px;
    height: 100%;
    overflow: auto;
  }
}
</style>