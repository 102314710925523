<template>
  <div class="parse-input">
    <el-input
      v-model="value.value"
      v-bind="value.props"
      :maxlength="500"
      show-word-limit
    ></el-input>
  </div>
</template>

<script>
export default {
  name: "ParseInput",
  props: {
    value: Object,
  },
};
</script>

<style lang="scss" scoped>
.parse-input {
}
</style>