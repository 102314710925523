<template>
  <div class="caption-parser colorGrey">
    {{ value.value }}
  </div>
</template>

<script>
export default {
  name: "CaptionParser",
  props: {
    value: Object,
  },
};
</script>

<style lang="scss" scoped>
.caption-parser {
  word-break: break-all;
}
</style>