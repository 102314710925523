<template>
  <div class="multiple-selector-parser">
    <el-select
      v-model="_modelData"
      :placeholder="value.props.placeholder"
      v-if="['multiMonthChildren', 'scheduleChildren'].includes(value.myChildrenType)"
      @change="handleChange"
      multiple
    >
      <el-option
        v-for="(item, index) in value.options"
        :key="index"
        :label="item.value"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <template v-else>
    <el-select
      v-model="value.value"
      :placeholder="value.props.placeholder"
      v-if="!value.isSpread"
      multiple
    >
      <el-option
        v-for="(item, index) in value.options"
        :key="index"
        :label="item.value"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <template v-if="value.isSpread">
      <el-checkbox-group class="checkbox-group" v-model="value.value">
        <el-checkbox
          :label="item.id"
          v-for="(item, index) in value.options"
          :key="index"
          class="checkbox"
        >
          <span>{{ item.value }}</span></el-checkbox
        >
      </el-checkbox-group>
    </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "MultipleSelectorParser",
  props: {
    value: Object,
    list: Array,
    parentId: String
  },
  watch:{
    'value.value':{
      immediate:true,
      deep:true,
      handler(v){
        if(v){
          this.getOtherValue(v)
        }
      }
    }
  },
  computed: {
    _modelData: {
      get() {
        return (
            this.value &&
            this.value.value &&
            this.value.value &&
            this.value.value.map(item => item.value)
          );
      },
      set (val) {
        let res = JSON.parse(JSON.stringify(this.value));
        res.value = val.map(ele => {
          let currentItem = this.value.options.find(item => item.id === ele)
          return {
            value: ele,
            name: currentItem ? currentItem.value : ''
          };
        })
        this.$emit("input", res);
      },
    },
  },
  methods: {
    getOtherValue(v){
      const userInfo = JSON.parse(localStorage.userInfo)
      if (this.value.myChildrenType == "scheduleChildren" ) {
        let currentItemIndex = this.list.findIndex(item => item.id === this.parentId)
        if (currentItemIndex || currentItemIndex === 0) {
          let shopCode = this.list[currentItemIndex].children.find(item => item.myName === 'shop').value.value
          let classifyId = this.list[currentItemIndex].children.find(item => item.myName === 'activityType').value.value
          let schedule = this.list[currentItemIndex].children.find(item => item.myName === 'activitySchedule').value.value
          let activityIds = this.list[currentItemIndex].children.find(item => item.myName === 'activityNames').value.map(item => item.value).join()
          if (this.value.myName == "activityNames" && userInfo.marketCode && shopCode && classifyId && schedule && activityIds) {
            let params = {
              marketCode: userInfo.marketCode,
              shopCode,
              classifyId,
              schedule,
              activityIds
            }
            this.$api.dynamicForm.emaRefundData(params).then(res => {
              if (res.code === 200) {
                this.list[currentItemIndex].children.forEach(item => {
                  if (item.myName === 'activityRefundInfo') {
                    item.value = res.data || []
                  }
                })
              }
            })
          }
        }
      }
    },
    handleChange(val) {
      if (this.value.myChildrenType == "scheduleChildren" ) {
        let currentItemIndex = this.list.findIndex(item => item.id === this.parentId)
        if (currentItemIndex || currentItemIndex === 0) {
          if (this.value.myName == "activityNames") {
            this.list[currentItemIndex].children.forEach(item => {
            if (item.myName === 'activityRefundInfo') {
              item.value = []
            }
          })
          }
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.multiple-selector-parser {
  .checkbox-group {
    display: flex;
    flex-direction: column;

    .checkbox {
      margin: 8px 0;
    }
  }
}
</style>