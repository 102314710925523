<template>
  <div class="date-time-picker-parser">
    <!-- 月份选择 -->
    <el-date-picker
    v-if="_type=='month'"
      v-model="value.value"
      value-format="timestamp"
      :type="_type"
      @change="select"
      :placeholder="_placeholder"
      v-bind="value.props"
      class="date-time-picker"
      :picker-options="_pickerOptions"
    >
    </el-date-picker>
    <!-- 时间选择 -->
    <el-date-picker
    v-else
      v-model="value.value"
      value-format="timestamp"
      :type="_type"
      @change="select"
      :placeholder="_placeholder"
      v-bind="value.props"
      class="date-time-picker"
    >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  name: "DateTimePickerParser",
  props: {
    value: Object,
    list: Array,
    parentId: String
  },
  watch:{
    'value.value':{
      immediate:true,
      deep:true,
      handler(v){
        if(v){
          this.getOtherValue(v)
        }
      }
    },
  },
  computed: {
    _pickerOptions(){
      return { 
                    disabledDate: (time) => {
                        var date = new Date();
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        if (month >= 1 && month <= 9) {
                            month = "0" + month;
                        }
                        var currentdate = year.toString()  + month.toString();

                        var timeyear = time.getFullYear();
                        var timemonth = time.getMonth() + 1;
                        if (timemonth >= 1 && timemonth <= 9) {
                            timemonth = "0" + timemonth;
                        }
                        var timedate = timeyear.toString() + timemonth.toString();
                        return currentdate <= timedate;
                    }
                }
    },
    _type() {
      return {
        "yyyy-MM": "month",
        "yyyy-MM-dd": "date",
        "yyyy-MM-dd HH:mm": "datetime",
      }[this.value.props.format];
    },
    _placeholder() {
      return {
        date: "选择日期",
        datetime: "选择日期时间",
      }[this.value.props.format];
    },
  },
  methods:{
    select(val){
      if(this._type=='date'){
        val = new Date(val).setHours(0,0,0)
      }else{
        val = new Date(val).setSeconds(0)
      }
      this.value.value = val
    },
    getOtherValue(v){
      const userInfo = JSON.parse(localStorage.userInfo)
      let shop2 = null
      this.list.forEach(item=>{
        if(item.myType=='ecash'){
          item.children.forEach(ite=>{
            if(ite.myName=="shop" && ite.myChildrenType=='ecashChildren'){
          shop2 = ite.value.value || null 
        }
          })
        }
      })
      let month = new Date(v).getFullYear() + '-' + (new Date(v).getMonth()+1).toString().padStart(2,'0')
      let cardType = null
      this.list.forEach(item=>{
        if(item.myType == 'ecash'){
          item.children.forEach(ite=>{
            if(ite.myName=='cardType'){
              cardType = ite.value
            }
          })
        }
      })
      if(cardType&&shop2){
        this.$api.dynamicForm.componentCardTypeRefund({marketCode: userInfo.marketCode, shopCode: shop2,cardType: cardType, month: month}).then(res=>{
            if(res.code==200 && res.data){
              this.list.forEach((item,index)=>{
              if(item.myType == 'ecash'){
                  item.children.forEach(ite=>{
              if(ite.myName=='commission'){
                // 手续费
                ite.value = res.data.feeAmount
              }
              if(ite.myName=='amount'){
                // 核销金额
                ite.value = + res.data.consumeAmount
              }
              if(ite.myName=='thisAmount'){
                // 本次请款金额
                ite.value = + res.data.refundAmount
              }
            })
                }
              })
            }
          })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.date-time-picker-parser {
  .date-time-picker {
    width: 100%;
  }
}
</style>